import { Box } from '@mui/material';
import CheckSuccessIcon from './Icons/CheckSuccessIcon';
import CheckProgressIcon from './Icons/CheckProgressIcon';
import CheckFailIcon from './Icons/CheckFailIcon';
import PropTypes from 'prop-types';

export const StatusBadge = ({ value, status }) => {
    let backgroundColor;
    let IconComponent;
    let color;

    if (status === 'success') {
        backgroundColor = '#ECF8F2';
        IconComponent = CheckSuccessIcon;
        color = '#0F6D03';
    } else if (status === 'warning') {
        backgroundColor = '#FDEFD8';
        IconComponent = CheckProgressIcon;
        color = '#955F04';
    } else if (status === 'error') {
        backgroundColor = '#FFF0F0';
        IconComponent = CheckFailIcon;
        color = '#C20600';
    } else {
        backgroundColor = '#E3E5E7';
        color = '#696F7C';
    }

    return (
        <Box
            sx={{
                display: 'flex',
                alignItems: 'center',
                backgroundColor,
                padding: '0 8px',
                borderRadius: '14px',
                color,
                width: 'fit-content',
                textAlign: 'center'
            }}
        >
            {IconComponent && (
                <Box sx={{ display: 'flex', alignItems: 'center', marginRight: '4px' }}>
                    <IconComponent />
                </Box>
            )}
            {value}
        </Box>
    );
};

StatusBadge.propTypes = {
    value: PropTypes.string.isRequired,
    status: PropTypes.oneOf(['success', 'warning', 'error', 'info'])
};

StatusBadge.defaultProps = {
    status: 'info'
};

export default StatusBadge;
