import PropTypes from 'prop-types';
import { useContext } from 'react';
import { renderSpinner } from '../../utils/Utility';
import { UserListContext } from '../../context/UserListContext';
import { UsersSelector } from '../../components/User/UsersSelector';

const minus = (minuendo, ...sottraendi) => {
  const bad = new Set(sottraendi.reduce((a, b) => a.concat(b), []));
  return minuendo.filter((item) => !bad.has(item.id));
};

export const SpeakerPartecipantSelector = ({
                                             participants,
                                             setParticipants,
                                             speakers,
                                             setSpeakers,
                                             company
                                           }) => {
  const { users, usersById, isLoadingUsers } = useContext(UserListContext);
  const availableUsers = users.filter(i => i.company === company);
  return (
    <div>
      {renderSpinner(isLoadingUsers)}
      <UsersSelector
        label="Partecipanti"
        availableUsers={minus(availableUsers, speakers)}
        usersById={usersById}
        selected={participants}
        setSelected={setParticipants}
      />
      <UsersSelector
        label="Speakers / organizzatori"
        availableUsers={minus(availableUsers, participants)}
        usersById={usersById}
        selected={speakers}
        setSelected={setSpeakers}
      />
    </div>
  );
};

SpeakerPartecipantSelector.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  participants: PropTypes.arrayOf(PropTypes.object).isRequired,
  setParticipants: PropTypes.func.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  speakers: PropTypes.arrayOf(PropTypes.object).isRequired,
  setSpeakers: PropTypes.func.isRequired,
  company: PropTypes.number.isRequired
};
