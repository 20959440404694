import _ from 'lodash';
import moment from 'moment';
import { useContext, useEffect, useState } from 'react';
import { UserContext } from '../../context/UserContext';
import { renderSpinner } from '../../utils/Utility';
import I3Modal from '../../components/Layout/I3Modal';
import I3ModalConfirmation from '../../components/Layout/I3ModalConfirmation';
import { PERMISSIONS } from '../../constants';
import { Unauthorized } from '../../components/Unauthorized';
import { RetrospectiveDataGrid } from './RetrospectiveDataGrid';
import { deleteRetrospective, getRetrospectiveByYearAndUsernames } from '../../utils/API/retrospective';
import { RetrospectiveForm } from './RetrospectiveForm';
import { MultiSelectUser } from '../../components/Form/MultiSelectUser';
import { UserListContext } from '../../context/UserListContext';
import { PlusCircle } from 'react-bootstrap-icons';
import { Button } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';

export const RetrospectivePage = () => {
  const { hasClaim } = useContext(UserContext);
  const [isLoading, setIsLoading] = useState(false);
  const [showUpsertModal, setShowUpsertModal] = useState(false);
  const { users } = useContext(UserListContext);
  const [retrospectives, setRetrospectives] = useState([]);
  const [showDeleteConfirmationModal, setShowDeleteConfirmationModal] = useState(false);
  const [selectedRetrospective, setSelectedRetrospective] = useState(undefined);
  const availableYears = _.range(2023, moment().year() + 1);
  const [year, setYear] = useState(moment().year());
  const [selectedUserIds, setSelectedUserIds] = useState([]);
  const navigate = useNavigate();

  if (!hasClaim(PERMISSIONS.RETROSPECTIVE_ADMINISTRATION)) {
    return (<Unauthorized />);
  }

  const handleAddClick = () => {
    setSelectedRetrospective(undefined);
    setShowUpsertModal(true);
  };

  const handleEditClick = (id) => navigate(`/retrospectives/${id}`);

  const handleDeleteClick = (id) => {
    const retrospectiveToDelete = retrospectives.find(i => i.id === id);
    setSelectedRetrospective(retrospectiveToDelete);
    setShowDeleteConfirmationModal(true);
  };

  const handleNotifyClick = (id) => {
    // const retrospective = retrospective.find(i => i.id === id);
    // setSelectedRetrospective(retrospective);
    // setShowDeleteConfirmationModal(true);
  };

  const handleConfirmDeleteClick = async () => {
    try {
      await deleteRetrospective(selectedRetrospective.id);
      setRetrospectives(retrospectives.filter(i => i.id !== selectedRetrospective.id));
    } finally {
      setShowDeleteConfirmationModal(false);
    }
  };

  useEffect(() => {
    setIsLoading(true);
    const getData = async () => {
      const usernames = users.filter(u => selectedUserIds.includes(u.id)).map(u => u.username) ?? [];
      setRetrospectives(await getRetrospectiveByYearAndUsernames(year, usernames));
    };
    getData().finally(() => setIsLoading(false));
  }, [year, selectedUserIds]);

  return (<>
    <div className="content__wrapper">
      <div className="headerPage content">
        <div className="title">Retrospettiva</div>
        <div className="spacer" />
        <div className="d-flex gap-3 form-group">
          <select
            className="yearContainer"
            defaultValue={year}
            onChange={({ target: { value } }) => setYear(+value)}
          >
            {availableYears.map((n) => (
              <option value={+n} key={+n}>
                {n}
              </option>
            ))}
          </select>
          {hasClaim(PERMISSIONS.RETROSPECTIVE_ADMINISTRATION) && (
            <MultiSelectUser
              users={users}
              selected={selectedUserIds}
              setSelected={setSelectedUserIds}
            />
          )}
          <Button variant="primary" onClick={() => handleAddClick()}>
            <PlusCircle />
            Inserisci
          </Button>
        </div>
      </div>
      <div className="content__fixed_height">
        <div className="tabsContainer" style={{ flex: '1 1 0px' }}>
          {isLoading
            ? (renderSpinner(true))
            : (<RetrospectiveDataGrid
              data={retrospectives}
              year={year}
              onEditClick={handleEditClick}
              onDeleteClick={handleDeleteClick}
              onNotifyClick={handleNotifyClick}

            />)
          }
        </div>
      </div>
    </div>
    <I3Modal
      show={showUpsertModal}
      onHide={() => setShowUpsertModal(false)}
      title={selectedRetrospective ? 'Modifica retrospettiva' : 'Aggiungi retrospettiva'}
    >
      <RetrospectiveForm
        item={selectedRetrospective}
        onClose={() => setShowUpsertModal(false)}
        onSubmitSuccess={(data) => navigate(`/retrospectives/${data.id}`)}
        filterUserIds={selectedUserIds}
      />
    </I3Modal>
    <I3ModalConfirmation
      show={showDeleteConfirmationModal}
      onHide={() => setShowDeleteConfirmationModal(false)}
      title="Cancella retrospettiva"
      questionTitle="Vuoi cancellare questa retrospettiva?"
      onConfirm={handleConfirmDeleteClick}
    />
  </>);
};
