import { API } from '../../constants';
import request from '../axios';
import { error } from '../notification';

export const getRetrospectiveGoalsByYearAndUsername = async (year, username = '') => {
    try {
        const { data } = await request.get(`${API.RETROSPECTIVE_GOALS}?year=${year}&username=${username}`);
        return data;
    } catch (e) {
        error('Errore', 'Caricamento fallito');
        throw e;
    }
}
