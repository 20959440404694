import styled from 'styled-components';
import { useEffect, useState, useCallback } from 'react';
import { AnchorElement } from './PageAnchorElement';
import PropTypes from 'prop-types';

const Anchor = styled.div`
    display: flex;
    flex-flow: column wrap;
    justify-content: flex-start;
    align-items: stretch;
    padding-top: 20px;
    gap: 12px;
    @media (max-width: 1080px) {
        display: none;
    }
`;

export const PageAnchors = ({
                              sectionRefs,
                              sectionContainerRef,
                              sections
                            }) => {
  const [visibleSection, setVisibleSection] = useState(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        for (const entry of entries) {
          if (entry.isIntersecting) {
            setVisibleSection(entry.target.id);
            break;
          }
        }
      },
      {
        threshold: [0.7],
        root: sectionContainerRef.current
      }
    );

    sectionRefs.forEach((s) => {
      if (s.current) {
        observer.observe(s.current);
      }
    });

    return () => {
      sectionRefs.forEach((s) => {
        if (s.current) {
          observer.unobserve(s.current);
        }
      });
    };
  }, [sectionContainerRef, sectionRefs]);

  const isVisible = useCallback(
    (anchor) => anchor === visibleSection,
    [visibleSection]
  );

  return (
    <Anchor>
      {sections.map((el) => (
        <div
          key={`page_anchor_${el.anchor}`}
          style={{
            display: 'block'
          }}
        >
          <AnchorElement
            anchor={`#${el.anchor}`}
            isVisible={isVisible(el.anchor)}
            name={el.name}
          />
        </div>
      ))}
    </Anchor>
  );
};

PageAnchors.propTypes = {
  sectionRefs: PropTypes.arrayOf(PropTypes.object),
  sectionContainerRef: PropTypes.objectOf(PropTypes.object),
  sections: PropTypes.arrayOf(PropTypes.object)
};

PageAnchors.defaultProps = {
  sectionRefs: [],
  sectionContainerRef: {},
  sections: []
};
