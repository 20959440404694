import { MultiSelectUser } from '../Form/MultiSelectUser';
import * as PropTypes from 'prop-types';

export const UsersSelector = ({
                                label,
                                availableUsers,
                                usersById,
                                selected,
                                setSelected
                              }) => (
  <div className="form-group">
    <label>
      {label}
      <div className="pills">
        {selected.map((id) => (
          <span
            key={+id}
            className="pill removable-pill"
            onClick={() => setSelected(selected.filter((u) => u !== id))}
          >
            {usersById[id].full_name}{' '}
          </span>
        ))}
      </div>
    </label>
    <br />
    <MultiSelectUser
      promptLabel={`Seleziona ${label}`}
      fixedPromptLabel
      emptySelectionLabel="Nessuno"
      users={availableUsers}
      selected={selected}
      setSelected={setSelected}
      variant="light"
      className="multi-select-small"
    />
  </div>
);

UsersSelector.propTypes = {
  label: PropTypes.string.isRequired,
  availableUsers: PropTypes.arrayOf(PropTypes.object).isRequired,
  usersById: PropTypes.objectOf(
    PropTypes.shape({
      id: PropTypes.number,
      full_name: PropTypes.string
    })
  ).isRequired,
  selected: PropTypes.arrayOf(PropTypes.number).isRequired,
  setSelected: PropTypes.func.isRequired
};