/* eslint-disable react/prop-types */
import * as Yup from 'yup';
import { Button } from 'react-bootstrap';
import { Form as FormikForm, Formik } from 'formik';
import { useEffect, useState } from 'react';
import FormItem from '../../components/Form/FormItem';
import { createRetrospective, getRetrospectiveUsersByYear } from '../../utils/API/retrospective';
import { renderSpinner } from '../../utils/Utility';

const initialValues = (currentData) => ({
  id: currentData?.id || '',
  date: currentData?.date || new Date().toISOString().split('T')[0],
  user: currentData?.user || ''
});

const validationSchema = Yup.object().shape({
  date: Yup.string().required('La data è obbligatoria'),
  user: Yup.string().required('L\'utente è obbligatorio')
});

export const RetrospectiveForm = ({
                                    item,
                                    onClose,
                                    onSubmitSuccess
                                  }) => {
  const [loading, setLoading] = useState(true);
  const [userOptions, setUserOptions] = useState([]);

  useEffect(() => {
    fetchUsers().then();
  }, []);

  const fetchUsers = async (date = new Date()) => {
    try {
      const users = await getRetrospectiveUsersByYear(date.getFullYear());
      setUserOptions(users);
    } catch (error) {
      console.error('Error fetching users:', error);
    } finally {
      setLoading(false);
    }
  };

  const onSubmit = async (values, actions) => {
    const { setSubmitting } = actions;
    const { id, date, user } = values;
    const payload = { id, date, user };

    try {
      const data = await createRetrospective(payload);
      onSubmitSuccess(data);
    } finally {
      setSubmitting(false);
    }
  };

  if (loading) {
    return (renderSpinner(true));
  }

  return (
    <Formik
      initialValues={initialValues(item)}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
    >
      {({
          handleSubmit,
          isSubmitting,
          isValid,
          isValidating,
          setFieldValue
        }) => (
        <FormikForm noValidate onSubmit={handleSubmit}>
          <p>
            Cerca e seleziona l&apos;utente per il quale desideri creare una nuova scheda di retrospettiva
          </p>
          <FormItem
            type="date"
            name="date"
            label="Seleziona data retrospettiva"
            maxLength="250"
            className="form-group"
            onChange={async (e) => {
              const { value } = e.target;
              setFieldValue('date', value);
              const newDate = new Date(value);
              if (newDate.getFullYear().toString().length >= 4) {
                await fetchUsers(newDate);
              }
            }}
          />

          <FormItem
            type="select"
            name="user"
            label="Seleziona utente"
            className="form-group"
            options={userOptions?.map((user) => (
              <option key={user.id} value={user.id}>
                {user.username.toUpperCase()}
              </option>
            ))}
          />

          <div className="buttonContent">
            <div className="spacer" />
            <Button
              onClick={onClose}
              variant="secondary"
            >
              Annulla
            </Button>
            <Button
              variant="primary"
              type="submit"
              disabled={isSubmitting || isValidating || !isValid}
            >
              Inserisci
            </Button>
          </div>
        </FormikForm>
      )}
    </Formik>
  );
};
