import _ from 'lodash';
import moment from 'moment';
import { useContext, useEffect, useMemo, useState } from 'react';
import { UserContext } from '../../context/UserContext';
import { renderSpinner } from '../../utils/Utility';
import { PERMISSIONS } from '../../constants';
import { RetrospectiveGoalsDataGrid } from './RetrospectiveGoalsDataGrid';
import { getRetrospectiveGoalsByYearAndUsername } from '../../utils/API/retrospectiveGoals';
import { MonoSelectUser } from '../../components/Form/MonoSelectUser';
import { UserListContext } from '../../context/UserListContext';

export const RetrospectiveGoalsPage = () => {
  const { hasClaim } = useContext(UserContext);
  const [isLoading, setIsLoading] = useState(false);
  const { users, usersById } = useContext(UserListContext);
  const [retrospectiveGoals, setRetrospectiveGoals] = useState([]);

  const availableYears = _.range(2023, moment().year() + 1);
  const [year, setYear] = useState(moment().year());
  const [userId, setUserId] = useState();
  const username = useMemo(
    () => usersById[userId]?.username,
    [userId, usersById]
  );

  useEffect(() => {
    setIsLoading(true);
    const getData = async () => {
      setRetrospectiveGoals(await getRetrospectiveGoalsByYearAndUsername(year, username));
    };
    getData().finally(() => setIsLoading(false));
  }, [year, username]);

  return (<>
    <div className="content__wrapper">
      <div className="headerPage content">
        <div className="title">Obiettivi</div>
        <div className="spacer" />
        <div className="d-flex gap-3 form-group">
          <select
            className="yearContainer"
            defaultValue={year}
            onChange={({ target: { value } }) => setYear(+value)}
          >
            {availableYears.map((n) => (
              <option value={+n} key={+n}>
                {n}
              </option>
            ))}
          </select>
          {hasClaim(PERMISSIONS.RETROSPECTIVE_ADMINISTRATION) && (
            <MonoSelectUser
              select={setUserId}
              users={users}
            />
          )}
        </div>
      </div>
      <div className="content__fixed_height">
        <div className="tabsContainer" style={{ flex: '1 1 0px' }}>
          {isLoading
            ? (renderSpinner(true))
            : (<RetrospectiveGoalsDataGrid data={retrospectiveGoals} />)
          }
        </div>
      </div>
    </div>
  </>);
};
