import PropTypes from 'prop-types';
import 'moment/locale/it';
import '../../css/tempo.scss';
import * as Yup from 'yup';
import { Button } from 'react-bootstrap';
import { Form as FormikForm, Formik } from 'formik';
import FormCheckbox from '../../components/Form/FormCheckbox';
import FormItem from '../../components/Form/FormItem';
import { SpeakerPartecipantSelector } from './SpeakerPartecipantSelector';
import { useContext, useState } from 'react';
import {
  addTrainingExpense,
  updateTrainingExpense,
} from '../../utils/API/budgets';
import moment from 'moment';
import { UserListContext } from '../../context/UserListContext';
import DropFileZone from '../../components/Form/DropFileZone';
import _ from 'lodash';
import FormDatePicker from '../../components/Form/FormDatePicker';

const initialValues = (currentData) => ({
  id: currentData?.id || undefined,
  date: currentData?.date ? moment(currentData.date, 'YYYY-MM-DD') : moment(),
  description: currentData?.description || '',
  place: currentData?.place || '',
  note: currentData?.note || '',
  ticket: currentData?.ticket || 0,
  room: currentData?.room || 0,
  plane: currentData?.plane || 0,
  highway: currentData?.highway || 0,
  other: currentData?.other || 0,
  attachment: currentData?.attachment,
  is_guild: currentData?.is_guild || false,
  invoice_made_out_to_company: currentData?.invoice_made_out_to_company || false,
});

const yupCost = Yup.number()
  .required('Campo obbligatorio')
  .min(0, 'Valore negativo non ammesso');
const validationSchema = Yup.object().shape({
  date: Yup.date().required('Campo obbligatorio'),
  description: Yup.string()
    .required('Campo obbligatorio')
    .min(5, 'Lunghezza minima 5 caratteri')
    .max(255, 'Lunghezza massima 255 caratteri'),
  place: Yup.string().max(255, 'Lunghezza massima 255 caratteri'),
  note: Yup.string().max(255, 'Lunghezza massima 255 caratteri'),
  ticket: yupCost,
  room: yupCost,
  plane: yupCost,
  highway: yupCost,
  other: yupCost,
  is_guild: Yup.boolean(),
  invoice_made_out_to_company: Yup.boolean(),
});

const smallField = { flex: '0 5 200px' };

export const BudgetForm = ({ close, currentData, updateData, company }) => {
  const isNew = !currentData?.id;

  const { usersById } = useContext(UserListContext);

  const [participants, setParticipants] = useState(
    currentData?.participants ?? []
  );
  const [speakers, setSpeakers] = useState(currentData?.speakers ?? []);

  const hasMembers = participants.length + speakers.length > 0;

  const [executeRemoveSelectedFiles, setExecuteRemoveSelectedFiles] =
    useState(false);

  const resetData = () => {
    setParticipants([]);
    setSpeakers([]);
    setExecuteRemoveSelectedFiles(true);
  };

  async function onSubmit(values, { setSubmitting, resetForm, setFieldValue }) {
    const { id, createAnother } = values;
    const p = participants
      .map((userId) => usersById[userId]?.username)
      .filter((u) => u);
    const s = speakers
      .map((userId) => usersById[userId]?.username)
      .filter((u) => u);
    const data = { ...values, date: values.date.format('YYYY-MM-DD') }
    try {
      if (id) {
        await updateTrainingExpense(data, p, s);
      } else {
        await addTrainingExpense(data, p, s);
      }
    } finally {
      setSubmitting(false);
      updateData();
      if (!createAnother) {
        close();
      } else {
        resetForm();
        setFieldValue('createAnother', createAnother);
        resetData();
      }
    }
  }

  const attachments = [];
  if (currentData && currentData.attachment) {
    const fileData = { name: _.get(currentData, 'attachment', null) };
    attachments.push({ fileData, deleted: false });
  }

  return (
    <Formik
      validationSchema={validationSchema}
      onSubmit={async (values, actions) => {
        await onSubmit(values, actions, close, updateData);
      }}
      initialValues={initialValues(currentData)}
    >
      {({ handleSubmit, isSubmitting, isValid, setFieldValue }) => (
        <FormikForm noValidate onSubmit={handleSubmit}>
          <div className="inline-fields">
            <FormDatePicker
              name="date"
              label="Data *"
            />
            <FormItem
              type="textarea"
              name="description"
              rows="1"
              label="Descrizione *"
            />
          </div>
          <div className="inline-fields">
            <FormItem
              type="input"
              name="place"
              label="Luogo"
              style={smallField}
            />
            <FormItem type="input" name="note" label="Note" />
          </div>
          <div className="inline-fields">
            <FormItem
              type="number"
              pattern="[0-9]+([\.,][0-9]{1,2})?"
              name="ticket"
              label="Costo biglietto (€)"
            />
            <FormItem
              type="number"
              pattern="[0-9]+([\.,][0-9]{1,2})?"
              name="room"
              label="Costo hotel (€)"
            />
            <FormItem
              type="number"
              pattern="[0-9]+([\.,][0-9]{1,2})?"
              name="plane"
              label="Costo voli (€)"
            />
            <FormItem
              type="number"
              pattern="[0-9]+([\.,][0-9]{1,2})?"
              name="highway"
              label="Costo pedaggi (€)"
            />
            <FormItem
              type="number"
              pattern="[0-9]+([\.,][0-9]{1,2})?"
              name="other"
              label="Altri costi (€)"
            />
          </div>
          <div className="inline-fields">
            <FormCheckbox name="is_guild" label="Spesa per gilda" />
            <FormCheckbox
              name="invoice_made_out_to_company"
              label="Fattura intestata all'azienda"
            />
          </div>
          <SpeakerPartecipantSelector
            participants={participants}
            setParticipants={setParticipants}
            speakers={speakers}
            setSpeakers={setSpeakers}
            company={company}
          />
          <DropFileZone
            name="attachment"
            fileTypes=".pdf, .png"
            text="Trascina il file oppure fai click per selezionare il file da allegare alla spesa"
            setFieldValue={setFieldValue}
            attachments={attachments}
            executeRemoveSelectedFiles={executeRemoveSelectedFiles}
            setExecuteRemoveSelectedFiles={setExecuteRemoveSelectedFiles}
            setIsFileAttachment={() => {}}
          />
          {isNew && (
            <>
              <hr />
              <FormCheckbox
                name="createAnother"
                label="Inserisci subito un'altra"
              />
            </>
          )}
          <div className="buttonContent">
            <Button variant="secondary" onClick={close}>
              Annulla
            </Button>
            <Button
              variant="primary"
              type="submit"
              disabled={isSubmitting || !isValid || !hasMembers}
            >
              {currentData?.id ? 'Aggiorna' : 'Inserisci'}
            </Button>
          </div>
        </FormikForm>
      )}
    </Formik>
  );
};

BudgetForm.propTypes = {
  close: PropTypes.func.isRequired,
  currentData: PropTypes.shape({
    id: PropTypes.number,
    date: PropTypes.string,
    description: PropTypes.string,
    duration: PropTypes.number,
    // eslint-disable-next-line react/forbid-prop-types
    participants: PropTypes.array,
    // eslint-disable-next-line react/forbid-prop-types
    speakers: PropTypes.array,
    // eslint-disable-next-line react/forbid-prop-types
    attachment: PropTypes.any,
  }).isRequired,
  updateData: PropTypes.func.isRequired,
  company: PropTypes.number.isRequired,
};
