import PropTypes from 'prop-types';
import { GridActionsCellItem, itIT } from '@mui/x-data-grid';
import { StripedDataGrid } from '../../components/DataGrid/StripedDataGrid';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import { StatusBadge } from '../../components/StatusBadge';
import { useMemo } from 'react';
import { Box } from '@mui/material';
import _ from 'lodash';

export const RetrospectiveDataGrid = ({
                                        data,
                                        year,
                                        onEditClick,
                                        onDeleteClick
                                      }) => {
  const outcomeStatusMap = {
    achieved: 'success',
    partially_achieved: 'warning',
    not_achieved: 'error'
  };

  const columns = useMemo(() => [
    {
      field: 'user',
      headerName: 'Utente',
      width: 70,
      valueGetter: (params) => params.row.user?.username.toUpperCase() || 'N/A'
    },
    {
      field: 'date',
      headerName: 'Data',
      width: 100,
      valueFormatter: ({ value }) => value ? new Date(value).toLocaleDateString() : ''
    },
    {
      field: 'interviewers',
      headerName: 'Intervistatori',
      width: 180,
      valueGetter: (params) => params.row.interviewers?.map(interviewer => interviewer.username.toUpperCase()).sort().join(', ') || ''
    },
    {
      field: 'percentage', 
      headerName: `Obiettivi ${year - 1}`, 
      width: 140,
      valueGetter: (value) => ({
        value: value.row.previousGoalsPercentage,
        label: !_.isNil(value.row.previousGoalsPercentage) ? `${Math.round(value.row.previousGoalsPercentage)}%` : 'Esito mancante',
        status: outcomeStatusMap[value.row.previousGoalsOutcome] || 'info'
      }),
      renderCell: (params) => (
        <StatusBadge value={params.value.label} status={params.value.status} />
      ),
      sortComparator: (v1, v2) => !_.isNil(v1.value) ? v1.value - v2.value : -1
    },
    {
      field: 'goals',
      headerName: `Obiettivi ${year}`,
      valueGetter: (value) => value.row.currentGoals.map(goal => goal.type.name).sort().join(', '),
      flex: 2
    },
    {
      field: 'comments',
      headerName: 'Commenti condivisi',
      flex: 1,
      renderCell: (params) => (
        <Box
          sx={{
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            maxWidth: '100%'
          }}
          title={params.value}
        >
          {params.value}
        </Box>
      )
    },
    {
      field: 'notes',
      headerName: 'Note interne',
      flex: 1,
      renderCell: (params) => (
        <Box
          sx={{
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            maxWidth: '100%'
          }}
          title={params.value}
        >
          {params.value}
        </Box>
      )
    },
    {
      field: 'actions',
      headerName: 'Azioni',
      type: 'actions',
      cellClassName: 'actions',
      width: 150,
      getActions: ({ id, row }) => [
        <GridActionsCellItem
          icon={<EditOutlinedIcon />}
          label="Edit"
          onClick={() => onEditClick(id)}
          color="inherit"
          key={`edit_${id}`}
        />,
        <GridActionsCellItem
          icon={<DeleteOutlinedIcon />}
          label="Delete"
          onClick={() => onDeleteClick(id)}
          color="inherit"
          key={`delete_${id}`}
          disabled={row.hasRetrospectiveGoals}
        />
        // <GridActionsCellItem
        //     icon={<SendOutlinedIcon/>}
        //     label="Notify"
        //     onClick={() => onNotifyClick(id)}
        //     color="inherit"
        //     key={`notify_${id}`}
        //     disabled={row.hasRetrospectiveGoals}
        // />,
      ]
    }
  ], [
    onEditClick,
    onDeleteClick
    // onNotifyClick
  ]);
  const rows = useMemo(() => JSON.parse(JSON.stringify(data)), [data]);

  return (
    <StripedDataGrid
      localeText={itIT.components.MuiDataGrid.defaultProps.localeText}
      rows={rows}
      columns={columns}
      density="standard"
      disableColumnMenu
      disableRowSelectionOnClick
      getRowClassName={(params) =>
        params.indexRelativeToCurrentPage % 2 === 0 ? 'even' : 'odd'
      }
      getRowHeight={() => 'auto'}
      initialState={{
        sorting: {
          sortModel: [
            { field: 'name', sort: 'asc' }
          ]
        },
        pagination: {
          paginationModel: {
            pageSize: 100
          }
        }
      }}
      pageSizeOptions={[25, 50, 100]}
      sx={{
        '&.MuiDataGrid-root--densityCompact .MuiDataGrid-cell': { py: '8px' },
        '&.MuiDataGrid-root--densityStandard .MuiDataGrid-cell': { py: '15px' },
        '&.MuiDataGrid-root--densityComfortable .MuiDataGrid-cell': { py: '22px' }
      }}
    />
  );
};

RetrospectiveDataGrid.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object),
  year: PropTypes.number,
  onEditClick: PropTypes.func,
  onDeleteClick: PropTypes.func
  // onNotifyClick: PropTypes.func,
};

RetrospectiveDataGrid.defaultProps = {
  data: [],
  year: new Date().getFullYear(),
  onEditClick: () => {
  },
  onDeleteClick: () => {
  }
  // onNotifyClick: () => {
  // },
};