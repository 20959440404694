/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/prop-types */
import Styled from 'styled-components';
import { Badge, Button } from 'react-bootstrap';
import { Form as FormikForm, Formik } from 'formik';
import { sendEmail } from '../../utils/API/user';
import FormItem from '../../components/Form/FormItem';

const FormContainer = Styled.div`
  margin-bottom: 15px;
`;

const ToLabel = Styled.span`
  margin-bottom: 5px;
  display: inline-block;
`;

const UsersContainer = Styled.div`
  padding: 15px;
  border: 1px solid #ccc;
  border-radius: 3px;
  margin-bottom: 15px;
`;

const initialValues = () => ({
  type: 'message', // ENUM
  note: ''
});

function onValidate() {
  return {};
}

function MailForm({ close, users }) {
  async function onSubmit(values, { setSubmitting }) {
    try {
      await sendEmail({ ...values, usernames: users.map((u) => u.username) });
      close();
      setSubmitting(false);
    } catch (e) {
      setSubmitting(false);
    }
  }

  const Users = () =>
    users.map((user) => (
      <div key={`user-${user.id}`}>
        <Badge bg="secondary">
          {user.first_name} {user.last_name}
        </Badge>{' '}
      </div>
    ));

  return (
    <Formik
      validate={onValidate}
      onSubmit={(values, actions) => {
        actions.setSubmitting(true);
        onSubmit(values, actions, close);
      }}
      initialValues={initialValues()}
    >
      {({ handleSubmit, isSubmitting, values }) => (
        <FormikForm noValidate onSubmit={handleSubmit}>
          <FormContainer>
            <FormItem
              type="textarea"
              rows="10"
              name="note"
              label="Testo"
              className="form-group"
            />
          </FormContainer>
          <ToLabel>Destinatari</ToLabel>
          <UsersContainer>
            <Users />
          </UsersContainer>
          <div className="buttonContent">
            <Button variant="secondary" onClick={close}>
              Annulla
            </Button>
            <Button
              variant="primary"
              type="submit"
              disabled={isSubmitting || values.note.length === 0}
            >
              Invia
            </Button>
          </div>
        </FormikForm>
      )}
    </Formik>
  );
}

export default MailForm;
