import PropTypes from 'prop-types';
import { useContext } from 'react';
import { UserListContext } from '../../context/UserListContext';
import { UsersSelector } from '../../components/User/UsersSelector';

export const InterviewersSelector = ({
                                       interviewers,
                                       setInterviewers
                                     }) => {
  const { users, usersById } = useContext(UserListContext);
  const availableUsers = users.filter((u) => u.groups.some(g => ['amministrazione', 'direzione', 'adm'].includes(g)));

  return (
    <div>
      <UsersSelector
        label="Intervistatori"
        availableUsers={availableUsers}
        usersById={usersById}
        selected={interviewers}
        setSelected={setInterviewers}
      />
    </div>
  );
};

InterviewersSelector.propTypes = {
  interviewers: PropTypes.arrayOf(PropTypes.number).isRequired,
  setInterviewers: PropTypes.func.isRequired
};
