import { useContext, useEffect, useState, useRef, useMemo } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { UserContext } from '../../context/UserContext';
import { PERMISSIONS } from '../../constants';
import { Unauthorized } from '../../components/Unauthorized';
import { BsArrowLeft } from 'react-icons/bs';
import { Button, Card, Container } from 'react-bootstrap';
import { Form as FormikForm, Formik } from 'formik';
import FormItem from '../../components/Form/FormItem';
import Styled from 'styled-components';
import {
  RetrospectiveGoalsAndResults
} from '../../components/RetrospectiveGoalsAndResults/RetrospectiveGoalsAndResults';
import { getRetrospective, updateRetrospective } from '../../utils/API/retrospective';
import { InterviewersSelector } from './InterviewersSelector';
import { renderSpinner } from '../../utils/Utility';
import LazyAvatar from '../../components/User/Avatar';
import { getRetrospectiveGoalTypes } from '../../utils/API/retrospectiveGoalTypes';
import * as Yup from 'yup';
import I3ModalConfirmation from '../../components/Layout/I3ModalConfirmation';
import { PageAnchors } from '../../components/PageAnchor/PageAnchor';

const validationSchema = Yup.object().shape({
  comments: Yup.string(),
  notes: Yup.string(),
  currentGoals: Yup.array().of(
    Yup.object().shape({
      type: Yup.object().shape({
        id: Yup.number().required('Campo obbligatorio')
      }),
      description: Yup.string().required('Campo obbligatorio')
    })
  )
});

const CARD_MARGIN = 50;
const StyledCard = Styled(Card)`
      margin-top: ${CARD_MARGIN}px;
      margin-bottom: ${CARD_MARGIN}px;
      border-radius: 12px;
      border: 1px solid var(--brand-light);
      padding: 2em;
    `;

export const RetrospectiveEditPage = () => {
  const { hasClaim } = useContext(UserContext);
  const navigate = useNavigate();
  const { id } = useParams();
  const [retrospective, setRetrospective] = useState(null);
  const [goalTypes, setGoalTypes] = useState([]);
  const [showDeleteConfirmationModal, setShowDeleteConfirmationModal] = useState(false);
  const [arrayHelpers, setArrayHelpers] = useState(undefined);
  const [selectedGoalIndex, setSelectedGoalIndex] = useState(undefined);

  const commentsAndNotesRef = useRef(null);
  const previousGoalsRef = useRef(null);
  const currentGoalsRef = useRef(null);
  const sectionContainerRef = useRef(null);

  const sectionRefs = [
    commentsAndNotesRef,
    previousGoalsRef,
    currentGoalsRef
  ];

  const sections = useMemo(() => {
    if (!retrospective) {
      return [];
    }
    const s = [
      { anchor: 'commentsAndNotes', name: 'Commenti e note' },
      { anchor: 'currentGoals', name: `Obiettivi ${retrospective.year}` }
    ];
    if (retrospective?.previousGoals?.length) {
      s.splice(1, 0, { anchor: 'previousGoals', name: `Obiettivi ${retrospective.year - 1}` });
    }
    return s;
  }, [retrospective]);

  useEffect(() => {
    const loadRetrospective = async () => {
      try {
        setGoalTypes(await getRetrospectiveGoalTypes());
        setRetrospective(await getRetrospective(id));
      } catch (error) {
        console.error('Errore nel caricamento della retrospettiva:', error);
      }
    };

    loadRetrospective().then();
  }, [id]);

  if (!hasClaim(PERMISSIONS.RETROSPECTIVE_ADMINISTRATION)) {
    return (<Unauthorized />);
  }

  const handleSaveClick = async (values) => {
    try {
      await updateRetrospective(id, values);
      navigate('/retrospectives');
    } catch (error) {
      console.error('Errore durante il salvataggio:', error);
    }
  };

  const handleDeleteClick = (helpers, index) => {
    setArrayHelpers(helpers);
    setSelectedGoalIndex(index);
    setShowDeleteConfirmationModal(true);
  };

  const handleConfirmDeleteClick = async () => {
    arrayHelpers.remove(selectedGoalIndex);
    setArrayHelpers(undefined);
    setSelectedGoalIndex(undefined);
    setShowDeleteConfirmationModal(false);
  };

  if (!retrospective) {
    return renderSpinner();
  }

  return (
    <div className="content__wrapper">
      <div className="headerPage content">
        <div className="title">
          <BsArrowLeft
            className="back-icon"
            onClick={() => navigate('/retrospectives')}
            style={{ cursor: 'pointer', marginRight: '10px' }}
          />
          Retrospettiva
        </div>
      </div>

      <div className="headerPage content">
        <div className="title">
          <LazyAvatar username={retrospective.user.username} />
          <div>
            <div>{retrospective.user.full_name}</div>
            <div className="subtitle">
              <span>Retrospettiva del: {retrospective.year}</span> | <span>Ultima mail inviata: {retrospective.lastNotificationDate}</span>
            </div>
          </div>
        </div>
        <div className="spacer" />
        <div className="d-flex gap-3 form-group">
          <Button variant="Secondary" onClick={() => navigate('/retrospectives')}>
            Annulla
          </Button>

          <Button variant="primary" type="submit" form="retrospective-form">
            Salva
          </Button>
        </div>
      </div>

      <div className="d-flex">
        <div className="anchors__column">
          <PageAnchors
            sectionRefs={sectionRefs}
            sectionContainerRef={sectionContainerRef}
            sections={sections}
          />
        </div>

        <div className="content__column" ref={sectionContainerRef}>
          <Container className="content__fixed_height">
            <Formik
              initialValues={{
                interviewers: retrospective.interviewers || [],
                comments: retrospective?.comments || '',
                notes: retrospective?.notes || '',
                currentGoals: retrospective.currentGoals || [],
                previousGoals: retrospective.previousGoals || []
              }}
              validationSchema={validationSchema}
              onSubmit={handleSaveClick}
            >
              {({ values, setFieldValue }) => (
                <FormikForm noValidate id="retrospective-form">
                  <StyledCard id="commentsAndNotes" ref={commentsAndNotesRef}>
                    <Card.Body>
                      <Card.Title style={{
                        borderBottom: '1px solid var(--gray-90)',
                        margin: '0 -48px',
                        padding: '0 48px 48px 48px'
                      }}>
                        <div className="text-bold">Commenti e note</div>
                        <div className="text-small">Inserisci gli appunti raccolti durante la retrospettiva e i commenti
                          fatti a posteriori.
                        </div>
                      </Card.Title>
                      <Card.Body>
                        <InterviewersSelector
                          interviewers={values.interviewers}
                          setInterviewers={(interviewers) => {
                            setFieldValue('interviewers', interviewers);
                          }}
                        />
                        <FormItem
                          type="textarea"
                          name="comments"
                          label="Commenti condivisi"
                          rows="5"
                          className="form-group"
                        />
                        <FormItem
                          type="textarea"
                          name="notes"
                          label="Note interne"
                          rows="5"
                          className="form-group"
                        />
                      </Card.Body>
                    </Card.Body>
                  </StyledCard>

                  {retrospective.previousGoals && retrospective.previousGoals.length > 0 && (
                    <div id="previousGoals" ref={previousGoalsRef}>
                      <RetrospectiveGoalsAndResults
                        objectName="previousGoals"
                        year={retrospective.year - 1}
                        mode="review"
                        data={values.previousGoals}
                        typeList={goalTypes}
                      />
                    </div>
                  )}
                  <div id="currentGoals" ref={currentGoalsRef}>
                    <RetrospectiveGoalsAndResults
                      objectName="currentGoals"
                      year={retrospective.year}
                      mode="edit"
                      data={values.currentGoals}
                      typeList={goalTypes}
                      onDelete={handleDeleteClick}
                    />
                  </div>
                </FormikForm>
              )}
            </Formik>
          </Container>
        </div>
      </div>

      <I3ModalConfirmation
        show={showDeleteConfirmationModal}
        onHide={() => setShowDeleteConfirmationModal(false)}
        title="Cancella Obiettivo"
        questionTitle="Vuoi cancellare questo obiettivo?"
        onConfirm={handleConfirmDeleteClick}
      />
    </div>);
};
